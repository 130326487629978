import { permissions } from 'helpers/permissions'
import { makeObservable, observable, action, reaction, computed } from 'mobx'

import companyStore from 'stores/companyStore'
import profileStore from 'stores/profileStore'
import staffStore from 'stores/staffStore'
import userStore from 'stores/userStore'

class ChatStore {
  contacts = {}
  minimised = true
  panel = 'contacts'
  to = {}
  totalUnreadCount = 0

  constructor() {
    makeObservable(this, {
      contacts: observable,
      minimised: observable,
      panel: observable,
      to: observable,
      totalUnreadCount: observable,

      tabUnreadCounts: computed,
      chatEnabled: computed,
      hasClientChat: computed,

      open: action,
      close: action,
      toggle: action,
      selectContact: action,
      back: action,
      updateUnread: action,
    })

    reaction(
      () => [staffStore.staffAndAdmin, profileStore.isClient, companyStore.company],
      ([staffs, isClient, company]) => {
        if (isClient) {
          this._updateStaffForClient(staffs, company)
        }
      },
    )

    reaction(
      () => [staffStore.staffAndAdmin, profileStore.isClient],
      ([staffs, isClient]) => {
        if (!isClient) {
          this._updateStaff(staffs)
        }
      },
    )

    reaction(
      () => [userStore.users, this.hasClientChat],
      ([clients, hasClientChat]) => {
        if (hasClientChat) {
          this._updateClients(clients)
        } else {
          this._clearClients()
        }
      },
    )
  }

  // COMPUTEDS

  get tabUnreadCounts() {
    const unreadCounts = Object.values(this.contacts).reduce(
      (counts, contact) => {
        const { tab } = contact
        const count = contact.unread?.count ?? 0
        counts[tab] += count
        return counts
      },
      { team: 0, clients: 0 },
    )
    if (!this.hasClientChat) {
      unreadCounts.clients = this.totalUnreadCount - unreadCounts.team
    }
    return unreadCounts
  }

  get chatEnabled() {
    return companyStore.chatEnabled
  }

  get hasClientChat() {
    return profileStore.isAdmin || profileStore.hasPermission(permissions.CHAT_CLIENTS)
  }

  // ACTIONS

  open({ id, name } = {}) {
    companyStore.getMyCompany()
    profileStore.chatAuth(profileStore.token)
    this.minimised = false
    if (id) {
      const contact = this.contacts[id] ?? { id, name }
      this.selectContact(contact)
    }
  }

  close() {
    this.minimised = true
  }

  toggle() {
    if (this.minimised) {
      this.open()
    } else {
      this.close()
    }
  }

  selectContact(contact) {
    this.to = contact
    this.panel = 'chat'
  }

  back() {
    this.panel = 'contacts'
    this.to = {}
  }

  setTotalUnread(count) {
    this.totalUnreadCount = count
  }

  updateUnread(counts) {
    let totalCount = 0
    for (const [id, count] of Object.entries(counts)) {
      const contact = this.contacts[id]
      totalCount += count
      if (contact) {
        if (count > contact.unread.count) {
          contact.unread.at = Date.now()
        }
        contact.unread.count = count
      }
    }
    this.totalUnreadCount = totalCount
  }

  // REACTIONS

  _updateStaffForClient = (staffs, company) => {
    const newContacts = {}
    for (const staff of staffs) {
      if (staff.roles?.admin || staff.permissions.includes(permissions.CHAT_CLIENTS)) {
        const { _id: id, name, photo } = staff
        const displayPhoto = photo ?? company.logo
        newContacts[id] = {
          id,
          name,
          photo: displayPhoto,
          subtitle: company.name,
          tab: 'team',
          unread: { at: 0, count: 0 },
        }
      }
    }
    for (const contact of Object.values(this.contacts)) {
      // Keep other contacts
      if (contact.tab !== 'team') {
        newContacts[contact.id] = contact
      } else {
        // Persist unread count
        const newContact = newContacts[contact.id]
        if (newContact) {
          newContact.unread = contact.unread
        }
      }
    }
    this.contacts = newContacts
  }

  _updateStaff = (staffs) => {
    const newContacts = {}
    for (const staff of staffs) {
      const { _id: id, name, photo } = staff
      newContacts[id] = { id, name, photo, subtitle: '', tab: 'team', unread: { at: 0, count: 0 } }
    }
    for (const contact of Object.values(this.contacts)) {
      // Keep other contacts
      if (contact.tab !== 'team') {
        newContacts[contact.id] = contact
      } else {
        // Persist unread count
        const newContact = newContacts[contact.id]
        if (newContact) {
          newContact.unread = contact.unread
        }
      }
    }
    this.contacts = newContacts
  }

  _clearClients = () => {
    const newContacts = {}
    for (const contact of Object.values(this.contacts)) {
      if (contact.tab === 'clients') continue
      newContacts[contact.id] = contact
    }
    this.contacts = newContacts
  }

  _updateClients = (clients) => {
    const newContacts = {}
    for (const client of clients) {
      const { _id: id, name, pets } = client
      const subtitle = pets ? pets.map((p) => p.name).join(', ') : 'No Pets'
      let { photo } = client
      if (!photo && pets.length) {
        photo = pets[0].photo
      }
      newContacts[id] = { id, name, subtitle, photo, tab: 'clients', unread: { at: 0, count: 0 } }
    }
    for (const contact of Object.values(this.contacts)) {
      // Keep other contacts
      if (contact.tab !== 'clients') {
        newContacts[contact.id] = contact
      } else {
        // Persist unread count
        const newContact = newContacts[contact.id]
        if (newContact) {
          newContact.unread = contact.unread
        }
      }
    }
    this.contacts = newContacts
  }

  // MISC

  getContacts(tab) {
    const contacts = Object.values(this.contacts).filter((contact) => !tab || contact.tab === tab)
    // Latest unread first
    const sorted = contacts.sort((a, b) => b.unread.at - a.unread.at)
    return sorted
  }
}

const chatStore = new ChatStore()

export default chatStore
